import React from "react"
import { Link } from "gatsby"

const GetInTouch = () => {
    return (
        <section className="landing-contact">
            <div className="container">
                <h2>Bel, app of mail ons!</h2>
                <p>Wil je je ideeën met ons delen? <br /> Wij horen het graag!</p>
                <Link to="/neemcontactop" className="btn btn-trt-primary mt-4">Neem contact op</Link>
            </div>
        </section>
    )
}

export default GetInTouch;