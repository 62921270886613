import React from "react"

const CollapsePanel = ({ listServices, id }) => {
    return (
        <div id={id}>
            <div className="services-sec">
                {
                    listServices.map(({ ariaControl, image, imgAlt, title, description }, i) => ListServices(ariaControl, image, imgAlt, title, description, i, id))
                }
            </div>
        </div>
    )
}

function ListServices(ariaControl, image, imgAlt, title, description, i, id) {
    return (
        <div className="collapse-services" key={i}>
            <button className={i === 0 ? " " : "collapsed"} data-toggle="collapse" data-target={'#' + ariaControl} aria-expanded={i === 0 ? "true" : "false"} aria-controls={ariaControl}>
                {title}
            </button>
            <div id={ariaControl} className={i === 0 ? "collapse show" : "collapse"} data-parent={'#' + id}>
                <div className="accordion-content">
                    {image ? <img src={image} alt={imgAlt} /> : null}
                    <p>{description}</p>
                </div>
            </div>
        </div >
    )
}

export default CollapsePanel;