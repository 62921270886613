import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import GetInTouch from '../components/get-in-touch'
import CollapsePanel from '../components/collapse-panel'
import trtData from '../content/trt-data.json'
import { Link } from "gatsby"
import Metatags from '../components/Metatags';
import MainArtWorkImg from "../../static/images/main-artwork.png"
import technology from "../../static/images/technology.png"
import duration from "../../static/images/duration.png"

import Loadable from '@loadable/component';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const HomePage = (props) => {

  function HomePortfolioSlider(sliderImg, imgAlt, title, description, technologyImg, projectDuration, i) {
    return (
      <div className="item" key={i}>
        <div className="row align-items-center">
          <div className="col-xl-7">
            <div className="landing-slider">
              <img src={sliderImg} alt={imgAlt} />
            </div>
          </div>
          <div className="col-xl-5">
            <div className="trt-projects">
              <h3>{title}</h3>
              <p>{description}</p>
              <div>
                <h4 className="d-none d-sm-flex">
                  <img src={technology} height="50" alt={imgAlt} />
                  Technologie
                    </h4>
                <div className="technology-sec">
                  {
                    technologyImg.map(({ icon }) => <img src={icon} alt={imgAlt} />)
                  }
                </div>
                <h4 className="d-none d-sm-flex">
                  <img src={duration} height="50" alt={imgAlt} />
                  Duur van het project
                    </h4>
                <p className="ml-5 pl-2 d-none d-sm-block">{projectDuration}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <section className="trt-banner">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 banner-title order-1 order-lg-0">
              <h1>Veel tekenwerk?<br />Groeien zonder extra personeel aan te nemen?<br />Neem contact met ons op!</h1>
              <div className="text-center d-block d-sm-none mb-4">
                <img className="w-100" src={MainArtWorkImg} alt="" />
              </div>
              <div className="col-lg-10 p-0">
                <p>Wij hebben een vakkundig team tekenaars, gevestigd in Vietnam. Zij werken snel en efficiënt waardoor jouw bedrijf kan groeien en de kosten beheersbaar blijven.</p>
              </div>
              <Link className="btn btn-trt-primary mr-4" to="/overons">Over ons</Link>
              <Link className="btn btn-trt-primary" to="/portfolio">Portfolio</Link>
            </div>
            <div className="col-lg-6  text-center d-none d-sm-block">
              <img className="w-100" src={MainArtWorkImg} alt="" />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-gradient">
        <section className="landing-portfolio">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-8 text-center">
                <h3 className="mb-sm-2 mb-4">Voorbeelden van ons werk</h3>
                <p className="d-none d-sm-block">IJzersterk Tekenwerk is trots op haar diversiteit qua klanten. Onze tekenaars leveren goed werk, zijn innovatief, denken met je mee en hebben ervaring in verschillende industrieën.</p>
              </div>
            </div>
            <div className="portfolio-slider">
              <OwlCarousel
                className="owl-theme"
                loop
                autoplay
                animateIn="false"
                animateOut="fadeOut"
                lazyLoad="true"
                navSpeed="2000"
                smartSpeed="1500"
                mouseDrag="false"
                responsive={
                  {
                    0: {
                      items: 1,
                    },
                    400: {
                      items: 1,
                    },
                    740: {
                      items: 1,
                    },
                    940: {
                      items: 1,
                    },
                  }
                }
              >
                {
                  trtData.homePortfolioSliderData.map(({ sliderImg, imgAlt, title, description, technologyImg, projectDuration }, i) => HomePortfolioSlider(sliderImg, imgAlt, title, description, technologyImg, projectDuration, i))
                }
              </OwlCarousel>
            </div>
            <div className="text-center mb-3 mt-3">
              <Link className="btn btn-trt-primary" to="/portfolio">Portfolio</Link>
            </div>
          </div>
        </section>
      </div>

      <section className="clients-say">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 text-center">
              <h3 className="mb-sm-2 mb-4">Klanten aan het woord</h3>
              <p className="d-none d-sm-block">Wij laten graag onze klanten aan het woord om hun ervaring met ons team te delen. Lees hoe we onze klanten ondersteunen, werk uit handen nemen en productie kunnen verhogen.</p>
            </div>
          </div>
          <div className="our-client-say">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay
              navSpeed="1000"
              autoplaySpeed="1000"
              mouseDrag="false"
              autoplayHoverPause="true"
              dots
              responsive={
                {
                  0: {
                    items: 1,
                  },
                  400: {
                    items: 1,
                  },
                  740: {
                    items: 2,
                  },
                  940: {
                    items: 2,
                  },
                }
              }
            >
              <div className="item">
                <div className="testimonial">
                  <h5>Van den Oetelaar Metaal</h5>
                  <p>De engineers weten waar ze mee bezig zijn en hebben ruime kennis van materialen en productie methoden. Wat het werk voor ons makkelijk maakt, is dat er veel minder tijd gaat zitten in het ontwerpen, uittekenen en uitwerken van de opdracht. De manier waarop wij communiceren ( Skype)  werkt perfect en snel. Zonder moeite kunnen bestanden, projecten en foto’s uitgewisseld worden.</p>
                </div>
              </div>
              <div className="item">
                <div className="testimonial">
                  <h5>JT Techniek</h5>
                  <p>Ijzersterk Tekenwerk werken voor ons grote, complexe frames uit. D.w.z. dat ze de originele tekening geheel aanpassen en de kokers voorzien van nokverbindingen zodat we veel tijd kunnen besparen in het lasproces. Daarnaast tekenen ze voor ons ook nieuwe samenstellingen uit, deze leveren wij dan in de vorm van een schets aan. Wij hebben goede ervaringen met de duidelijke tekeningen – een service die wij niet in eigen huis kunnen.</p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section className="industries">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 text-center">
              <h3 className="mb-sm-2 mb-5">Industrieën</h3>
              <p className="d-none d-sm-block">Wij bieden een brede service binnen het 3D tekenbereik: Constructie - Buislaser en plaatlasertechnieken - Machinebouw - Pointcloud verwerking - Omzetten 2d naar 3d</p>
            </div>
          </div>
          <div className="row justify-content-center mobile-industries">
            <div className="col-sm-4 col-lg-2 col-4 d-flex justify-content-center">
              <div className="industries-types">
                <div className="industries-title">
                  <div>Constructie</div>
                </div>
                <div className="dot-line"></div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2 col-4 d-flex justify-content-center">
              <div className="industries-types">
                <div className="industries-title">
                  <div>Buislaser en plaatlaser technieken</div>
                </div>
                <div className="dot-line"></div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2 col-4 d-flex justify-content-center">
              <div className="industries-types">
                <div className="industries-title">
                  <div>Machinebouw</div>
                </div>
                <div className="dot-line"></div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2 col-4 d-flex justify-content-center">
              <div className="industries-types">
                <div className="industries-title">
                  <div>Pointcloud verwerking</div>
                </div>
                <div className="dot-line"></div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2 col-4 d-flex justify-content-center">
              <div className="industries-types">
                <div className="industries-title">
                  <div>Omzetten 2d naar 3d</div>
                </div>
                <div className="dot-line"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="impact-through-technology">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 text-center">
              <h3 className="mb-sm-2 mb-5">Insourcing versus Outsourcing</h3>
              <p className="d-none d-sm-block">Er is steeds meer mogelijk met verschillende technieken. Door onze service hebben verschillende bedrijven al een flinke stap kunnen zetten in het opzetten van hun eigen 3D bibliotheek.</p>
              <p className="d-none d-sm-block">Zelf zagen, boren of knippen? Of toch alles doorsturen om het extern (met een laser) te laten snijden en zetten? IJzersterk Tekenwerk maakt de keuze makkelijker door het uitwerken van alle productieprocessen.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-4 col-lg-2 col-4">
              <div className="impact-sec">
                <h4>100+</h4>
                <p>Jaren gezamenlijke ervaring</p>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2 col-4">
              <div className="impact-sec">
                <h4>1000+</h4>
                <p>Projecten</p>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2 col-4">
              <div className="impact-sec">
                <h4>10</h4>
                <p>Vietnamese engineers</p>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2 col-4">
              <div className="impact-sec">
                <h4>2</h4>
                <p>Internationale kantoren</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="services-provide">
        <div className="container">
          <h3>Onze service</h3>
          <CollapsePanel listServices={trtData.listServices} id="listServices"></CollapsePanel>
        </div>
      </div>

      <GetInTouch />

    </Layout >
  )
}

export default HomePage;